import React from "react";
import { PreCard } from "./PreCard";
import cricket from "./cricket.jpg";
import badminton from "./Badminton Event'24.png";
import snap from "./snap.jpg";
import bicycling from "./bicycling.jpg";
import donation from "./donation.jpg";
import nature from "./nature.jpg";
import running from "./running.jpg";
import { FaHome } from "react-icons/fa";

export const Pre_Events = () => {
  return (
    <div style={{ textAlign: "center", padding: "40px" }}>
      {/* Home Icon */}
      <div
        style={{
          position: "fixed",
          top: "20px",
          left: "20px",
          padding: "10px",
          background: "rgba(59, 116, 148, 0.3)",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <a href="/">
          <FaHome style={{ fontSize: "30px", color: "#0092ca" }} />
        </a>
      </div>

      {/* Heading */}
      <h1
        style={{
          fontFamily: "Arial, sans-serif",
          color: "#3498db",
          fontSize: "2.8rem",
          fontWeight: "bold",
          marginBottom: "30px",
        }}
      >
        Pre-Events
      </h1>

      {/* Grid Layout for Cards */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
          gap: "25px",
          justifyContent: "center",
          padding: "20px",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        <PreCard
          src={snap}
          alt="Sankalan National Assessment Program"
          title="Sankalan National Assessment"
          description="The Sankalan National Assessment Program by SoCE, IIT Kanpur brings you an unparalleled opportunity to test your knowledge and skills at a national level!"
          color="#ff5733"
        />
        <PreCard
          src={cricket}
          alt="Cricket Tournament"
          title="Cricket Tournament"
          description="The first-ever cricket tournament for the Civil Engineering department of IITK as part of the pre-events of SANKALAN’24."
          color="#27ae60"
        />
        <PreCard
          src={badminton}
          alt="Badminton Tournament"
          title="Badminton Tournament"
          description="A competitive Badminton Tournament, bringing together sports enthusiasts as part of SANKALAN’24 pre-events."
          color="#f39c12"
        />
        <PreCard
          src={bicycling}
          alt="Bicycling Event"
          title="Bicycling Event"
          description="A thrilling bicycling event for the campus junta to celebrate sports and fitness at SANKALAN’24."
          color="#8e44ad"
        />
        <PreCard
          src={running}
          alt="Running Event"
          title="Running Event"
          description="A high-energy running event promoting fitness and community engagement before SANKALAN’24."
          color="#2980b9"
        />
        <PreCard
          src={nature}
          alt="Nature Awareness Drive"
          title="Nature Awareness Drive"
          description="A drive to spread awareness about the importance of nature and sustainability as part of SANKALAN’24."
          color="#2ecc71"
        />
        <PreCard
          src={donation}
          alt="Donation Drive"
          title="Donation Drive"
          description="“We can’t help everyone, but…Everyone can help someone.”– Dr. Loretta Scott. The DONATION DRIVE EVENT’24 aims to provide the needy with basic requirements with the support of the entire campus community."
          color="#e74c3c"
        />
      </div>
    </div>
  );
};
