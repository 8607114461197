import React from "react";
import "./Team.css";
import {
  FaLinkedin,
  FaFacebook,
  FaHome,
  FaMailBulk,
  FaEnvelope,
  FaGlobe,
  FaInstagram,
} from "react-icons/fa";

import pghosh from "./Core-Team/priyanka-ghosh-iitk.jpg";
import balaji from "./Core-Team/Balaji-Devaraju.jpg";
import partha from "./Core-Team/parthasir.jpeg";
import cksahu from "./Core-Team/cksahu.jpg";
import prabin from "./Core-Team/Prabin-Ashish.jpg";
import shubham from "./Core-Team/shubham.jpeg";
import shrey from "./Core-Team/shrey.jpg";
import sandipan from "./Core-Team/sandipan.jpg";
import saurabh from "./Core-Team/saurabh.jpg";
import baban from "./Core-Team/baban.jpg";
import aditya from "./Core-Team/aditya.jpg";
import aadya from "./Core-Team/aadya.jpg";
import shambhavi from "./Core-Team/shambhavi.jpg";
import vedant from "./Core-Team/vedant.jpg";
import shubhamphd from "./Core-Team/shubhamphd.jpg";
import mansa from "./Core-Team/mansa.jpg";
import Deepesh from "./Core-Team/Deepesh.jpg";
import Pranay from "./Core-Team/Pranay.jpg";
import Sahil from "./Core-Team/Sahil.jpg";
import Kuldeep from "./Core-Team/Kuldeep.jpeg";
import Ruthvik from "./Core-Team/Ruthvik.jpg";
import Lokesh from "./Core-Team/Lokesh.jpg";
import Devagya from "./Core-Team/Devagya.jpg";
import Rithvik from "./Core-Team/Rithvik.jpg";
import Karishma from "./Core-Team/Karishma.jpg";
import Samanvitha from "./Core-Team/Samanvitha.jpg";

export default function Team({ isMobileView, isExpanded }) {
  const iconSize = 30;
  const getMarginLeft = (isMobileView, isExpanded) => {
    return isMobileView ? "0" : isExpanded ? "220px" : "45px";
  };

  return (
    <>
      <section
        className="contact"
        id="contact"
        style={{
          marginLeft: getMarginLeft(isMobileView, isExpanded),
          overflowX: "scroll",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "60px",
            left: "20px",
            padding: "10px",
            background: "rgba(59, 116, 148, 0.3)",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <a href="/">
            <FaHome style={{ fontSize: "40px", color: "#00FFFF" }} />
          </a>
        </div>
        <h1
          style={{ "font-family": "'Montserrat', sans-serif" }}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          OUR TEAM
        </h1>

        <div
          className="cards"
          style={{
            "font-family": "'Montserrat', sans-serif",
          }}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          {/* <h3>
            HEAD OF <span>DEPARTMENT</span>
          </h3>
          <div className="inner-card">
            <div className="card">
              <div className="image">
                <img src={pghosh} alt="" />
              </div>
              <div className="text">
                <p className="name">Dr. Priyanka Ghosh </p>

                <p className="email">
                  <i>Head Dept. Civil Engineering</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:priyog@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/priyanka-ghosh-33173314/?originalSubdomain=in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://iitk.ac.in/new/priyanka-ghosh"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGlobe size={iconSize} />
                </a>
              </div>
            </div>
          </div> */}
          <h3>
            CONCLAVE FACULTY <span>MEMBERS</span>
          </h3>
          <div className="inner-card">
            <div className="card">
              <div className="image">
                <img src={cksahu} alt="" />
              </div>
              <div className="text">
                <p className="name">Dr. Chunendra Sahu </p>

                <p className="email">
                  <i>Conclave Coordinator</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:cksahu@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/chunendra-k-sahu-b90aa33a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.iitk.ac.in/new/chunendra-k-sahu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGlobe size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card">
              <div className="image">
                <img src={partha} alt="" />
              </div>
              <div className="text">
                <p className="name">Dr. Partha Mishra</p>

                <p className="email">
                  <i> Executive Member</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:pnmishra@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://au.linkedin.com/in/parthanmishra"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.iitk.ac.in/new/partha-narayan-mishra"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGlobe size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card">
              <div className="image">
                <img src={prabin} alt="" />
              </div>
              <div className="text">
                <p className="name">Dr. Prabin Kumar</p>

                <p className="email">
                  <i> Executive Member</i>
                </p>
              </div>

              <div className="links">
                <a
                  href="mailto:pkashish@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://in.linkedin.com/in/prabin-kumar-ashish-94197b18"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.iitk.ac.in/new/prabin-kumar-ashish"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGlobe size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card">
              <div className="image">
                <img src={balaji} alt="" />
              </div>
              <div className="text">
                <p className="name">Dr. Balaji Devaraju</p>

                <p className="email">
                  <i> Executive Member</i>
                </p>
              </div>

              <div className="links">
                <a
                  href="mailto:dbalaji@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://iitk.ac.in/new/balaji-devaraju"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGlobe size={iconSize} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="cards"
          style={{ "font-family": "'Montserrat', sans-serif" }}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <h3>
            CONCLAVE STUDENT CORE <span>TEAM</span>
          </h3>
          <div className="inner-card">
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={Pranay} alt="" />
              </div>
              <div className="text">
                <p className="name">Pranay Saxena </p>

                <p className="email">
                  <i>Head, Events</i>
                </p>
                <p className="no">
                  <i>+91-8769639498</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:pranays22@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/pranay-saxena-0971aa258/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.instagram.com/pranay_s22/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={Karishma} alt="" />
              </div>
              <div className="text">
                <p className="name">Gousia Karishma Syed</p>

                <p className="email">
                  <i>Head, Events</i>
                </p>
                <p className="no">
                  <i>+91-9398537452</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:sgousia23@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/gousia-karishma-syed/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.instagram.com/karishma___syed/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={Sahil} alt="" />
              </div>
              <div className="text">
                <p className="name">Sahil Arolia </p>

                <p className="email">
                  <i>Head, Web</i>
                </p>
                <p className="no">
                  <i>+91- 7389959652</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:asahil22@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/sahil-arolia-1a2bb8259/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.instagram.com/sahil_arolia?igshid=ZDc4ODBmNjlmNQ%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={Deepesh} alt="" />
              </div>
              <div className="text">
                <p className="name">Deepesh Maurya </p>

                <p className="email">
                  <i>Head, Web</i>
                </p>
                <p className="no">
                  <i>+91-7318341820</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:deepeshm22@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/deepesh-maurya-386486251/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.instagram.com/djshakya_?igshid=NGExMmI2YTkyZg%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={Kuldeep} alt="" />
              </div>
              <div className="text">
                <p className="name">Kuldeep </p>

                <p className="email">
                  <i>Head, Design</i>
                </p>
                <p className="no">
                  <i>+91-9460664611</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:kuldeepb22@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/404/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.instagram.com/kuldeep_0_555?igshid=ZDc4ODBmNjlmNQ%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={Ruthvik} alt="" />
              </div>
              <div className="text">
                <p className="name">Ruthvik Sai Kalahastri </p>

                <p className="email">
                  <i>Head, Marketing & Finance</i>
                </p>
                <p className="no">
                  <i>+91-7013068069</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:ksruthvik22@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/404/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.instagram.com/ruthvik_2005_/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={Lokesh} alt="" />
              </div>
              <div className="text">
                <p className="name">Lokesh Sunda </p>

                <p className="email">
                  <i>Head, Marketing & Finance</i>
                </p>
                <p className="no">
                  <i>+91-8741811383</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:lokeshs22@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/lokesh-sunda-7b0773260/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/lokesh-sunda-7b0773260/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={Devagya} alt="" />
              </div>
              <div className="text">
                <p className="name">Devagya Singh Vats</p>

                <p className="email">
                  <i>Head, Content and Projects</i>
                </p>
                <p className="no">
                  <i>+91-7042510704</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:devagyasv22@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/devagya-vats-a30657261/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.instagram.com/devagyavats?igsh=bjlqZHFsd3JoNHNp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
