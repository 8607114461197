export const PreCard = ({ src, alt, title, description, color }) => {
  return (
    <div className="card-container">
      <img src={src} alt={alt} className="card-image" />
      <div className="card-overlay">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>

      <style jsx>
        {`
          .card-container {
            position: relative;
            width: 280px;
            height: 350px;
            border-radius: 1rem;
            overflow: hidden;
            cursor: pointer;
            background: var(--c, #0009);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
          .card-image {
            width: 100%;
            height: 100%;
            object-fit: contain; /* No cropping */
            transition: transform 0.4s ease-in-out;
          }
          .card-overlay {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: rgba(0, 0, 0, 0.7);
            color: white;
            padding: 20px;
            opacity: 0;
            transition: opacity 0.4s ease-in-out;
          }
          .card-container:hover .card-overlay {
            opacity: 1;
          }
          .card-container:hover .card-image {
            transform: scale(1.05);
          }
          h2 {
            font-size: 1.4rem;
            margin-bottom: 10px;
          }
          p {
            font-size: 1rem;
          }
        `}
      </style>
    </div>
  );
};
