import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import headerImg2 from "../assets/img/logo-combined-white.png";
import TrackVisibility from "react-on-screen";
import { FaCheckCircle, FaHandHoldingHeart } from "react-icons/fa";

const StyledSection = styled.section`
  background: #121212;
  min-height: 100vh;
  padding: 60px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

/* Subtle animated gradient overlay */
const GradientOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.05), transparent);
  animation: gradientMove 5s infinite alternate;

  @keyframes gradientMove {
    0% {
      transform: translateX(-20px);
    }
    100% {
      transform: translateX(20px);
    }
  }
`;

const Title = styled.h1`
  font-size: 48px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
`;

const SubTitle = styled.h4`
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #cccccc;
`;

const Description = styled.p`
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #bbbbbb;
  max-width: 800px;
  margin: 20px auto;
  text-align: justify;
  line-height: 1.6;
`;

const StyledButton = styled.button`
  padding: 12px 24px;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  color: #121212;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #f1f1f1;
    transform: scale(1.05);
  }
`;

const BannerMobile = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(100 - Math.random() * 100);
  const toRotate = ["उत्तमस्य जगतः कृते", "For a Better World"];
  const period = 500;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);
    return () => clearInterval(ticker);
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(500);
    }
  };

  return (
    <StyledSection id="home">
      <GradientOverlay />
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <Title>Sankalan - <span className="txt-rotate">{text}</span></Title>
                  <SubTitle>5th & 6th April, 2025</SubTitle>
                  <Description>
                    The Society of Civil Engineers (SoCE) at IIT Kanpur is one of the oldest and most prestigious technical communities, fostering innovation and knowledge exchange in civil engineering.
                  </Description>
                  <StyledButton onClick={() => window.open("https://unstop.com/f/iITqclk", "_blank")}>
                    Register Here
                  </StyledButton>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={headerImg2} alt="Header Img" width="80%" />
          </Col>
        </Row>
      </Container>
    </StyledSection>
  );
};

export default BannerMobile;
