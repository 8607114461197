import React from "react";
import "./Workshops.css";
import { getMarginLeft } from "../../utils/getMarginLeft";

function Workshops({ isMobileView, isExpanded }) {
  const handleGetWorkshops = () => {
    window.open(
      "https://unstop.com/f/iITqclk?lb=n6aLdjXi&utm_medium=Share&utm_source=WhatsApp",
      "_blank"
    );
  };

  const handleWorkshop1 = () => {
    window.open(
      "https://docs.google.com/document/d/1n0hlHoF_UC59-6ZkgFia7PuuU7bBzPsd0jMM1lZxie0/edit?usp=sharing",
      "_blank"
    );
  };

  const handleWorkshop2 = () => {
    window.open(
      "https://docs.google.com/document/d/11Eip_gfyDf6KVCwqwLxxCIP0eg3tutGboESno2KZ1FQ/edit",
      "_blank"
    );
  };

  return (
    <div
      className="workshops-container"
      style={{ marginLeft: getMarginLeft(isMobileView, isExpanded) }}
    >
      <div className="guidelines-workshops">
        <h4 className="workshop-heading">Workshops Guidelines:</h4>
        <ul className="workshops-list">
          <li>
            <strong>Registration Guidelines:</strong>
            <ul>
              <li>
                To be eligible for incentives, please complete the registration
                process by paying the registration fees and attending the
                workshop on both days.
              </li>
              <li>
                If you require accommodation, kindly find the details by
                clicking{" "}
                <a href="/accommodation">
                  <strong>here</strong>
                </a>
                .
              </li>
            </ul>
          </li>
          <li>
            <strong>Incentives:</strong>
            <ul>
              <li>Certificate</li>
              <li>Goodies</li>
            </ul>
          </li>
          <li>
            <strong>Requirements:</strong>
            <ul>
              <li>Laptop</li>
              <li>Charger</li>
            </ul>
          </li>
          <li>
            <strong>Fees:</strong> NA (Only the participants who have registered
            for Sankalan are eligible for Workshops)
          </li>
          <li>
            <strong>Venue:</strong> IIT Kanpur
          </li>
        </ul>
      </div>

      <div className="workshops-cards">
        <div className="workshop-card">
          <h3 className="workshop-title">
            Structural Design Practices using Soft Computing Applications
          </h3>
          <p className="workshop-instructor">
            <strong>
              Mr. Rohit Yadav (Managing Director, TeXel Consulting Engineers
              Pvt. Ltd.)
            </strong>
          </p>
          <ul className="workshop-details">
            <li>Introduction to Structural design and analysis</li>
            <li>Geometrical modelling of structures</li>
            <li>Case study of projects</li>
            <li>BIS used in structural design practices</li>
            <li>Discussions</li>
          </ul>
          <div className="workshop-buttons">
            <button className="workshop-btn" onClick={handleWorkshop1}>
              Learn More
            </button>
            <button className="workshop-btn" onClick={handleGetWorkshops}>
              Register Here
            </button>
          </div>
        </div>

        <div className="workshop-card">
          <h3 className="workshop-title">
            Enabling Smart Cities: Comprehensive Workshop on AI-ML
          </h3>
          <p className="workshop-instructor">
            <strong>Ms. Sanjita Prajapati (Research Assistant at IIT Kanpur)</strong>
          </p>
          <ul className="workshop-details">
            <li>Introduction and Subsets of AI</li>
            <li>Academic and Industry Difference</li>
            <li>CVPR, Tensorflow, PyTorch, etc.</li>
            <li>Applied AI</li>
            <li>AI in Civil Engineering with Problem Statements</li>
            <li>And many more exciting topics</li>
          </ul>
          <div className="workshop-buttons">
            <button className="workshop-btn" onClick={handleWorkshop2}>
              Learn More
            </button>
            <button className="workshop-btn" onClick={handleGetWorkshops}>
              Register Here
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Workshops;
