import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import { FaCheckCircle, FaHandHoldingHeart } from "react-icons/fa";

const Banner = () => {
  const texts = ["उत्तमस्य जगतः कृते", "For the Betterment of the World"];
  const [textIndex, setTextIndex] = useState(0);

  // Loop text every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleRegisterUnstop = () => {
    window.open(
      "https://unstop.com/f/iITqclk?lb=n6aLdjXi&utm_medium=Share&utm_source=WhatsApp",
      "_blank"
    );
  };

  return (
    <section
      className="banner d-flex flex-column justify-content-center align-items-center text-center"
      id="home"
      style={{
        height: "100vh",
        backgroundColor: "#000",
        color: "#fff",
        padding: "0 20px",
      }}
    >
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} md={10} lg={8}>
            <h1 style={{ fontSize: "4rem", fontFamily: "Times New Roman" }}>
              SANKALAN-25
            </h1>

            {/* Animated Hindi & English text */}
            <motion.h2
              key={textIndex}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 1 }}
              style={{
                fontSize: "1.8rem",
                color: "#f5a623",
                marginBottom: "20px",
              }}
            >
              {texts[textIndex]}
            </motion.h2>

            <div className="tagline-date">
              <h4>
                <b>
                  5<sup>th</sup> & 6<sup>th</sup> April, 2025
                </b>
              </h4>
            </div>

            <p
              style={{
                maxWidth: "800px",
                margin: "20px auto",
                textAlign: "justify",
                fontSize: "1.2rem",
              }}
            >
              Established more than five decades ago, the Society of Civil
              Engineers (SoCE) is the oldest departmental society at the Indian
              Institute of Technology Kanpur. We are driven by a shared
              enthusiasm and a commitment to promote a sense of community,
              academic and professional development, and a passion for civil
              engineering.
            </p>
            <div
  style={{
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  }}
>
  <button
    className="register-btn-unstop text-dark mt-4"
    onClick={handleRegisterUnstop}
    style={{
      padding: "10px 20px",
      fontSize: "1.2rem",
      backgroundColor: "#f5a623",
      border: "none",
      borderRadius: "5px",
      color: "white",
      cursor: "pointer",
    }}
  >
    <strong>Register Here</strong>
  </button>
</div>

          </Col>
        </Row>
      </Container>

      <div className="links-container" style={{ marginTop: "30px", textAlign: "center" }}>
  <a
    href="https://unstop.com/college-fests/sankalan25-civil-conclave-indian-institute-of-technology-iit-kanpur-294513?lb=n6aLdjXi&utm_medium=Share&utm_source=WhatsApp"
    className="register-tag"
    target="_blank"
    rel="noopener noreferrer"
    style={{
      display: "inline-flex",
      alignItems: "center",
      backgroundColor: "#28a745",
      padding: "12px 24px",
      color: "white",
      textDecoration: "none",
      borderRadius: "8px",
      marginRight: "15px",
      fontSize: "1.1rem",
      fontWeight: "bold",
      boxShadow: "0 4px 10px rgba(40, 167, 69, 0.3)",
      transition: "all 0.3s ease-in-out",
      position: "relative",
      overflow: "hidden",
    }}
    onMouseEnter={(e) => (e.target.style.boxShadow = "0 0 15px 5px rgba(40, 167, 69, 0.6)")}
    onMouseLeave={(e) => (e.target.style.boxShadow = "0 4px 10px rgba(40, 167, 69, 0.3)")}
  >
    <FaCheckCircle size={20} style={{ marginRight: "15px" }} />
    Register Here
  </a>

  <a
    href="/contribution"
    className="alum-support-tag"
    style={{
      display: "inline-flex",
      alignItems: "center",
      backgroundColor: "#007bff",
      padding: "12px 24px",
      color: "white",
      textDecoration: "none",
      borderRadius: "8px",
      fontSize: "1.1rem",
      fontWeight: "bold",
      boxShadow: "0 4px 10px rgba(0, 123, 255, 0.3)",
      transition: "all 0.3s ease-in-out",
      position: "relative",
      overflow: "hidden",
    }}
    onMouseEnter={(e) => (e.target.style.boxShadow = "0 0 15px 5px rgba(0, 123, 255, 0.6)")}
    onMouseLeave={(e) => (e.target.style.boxShadow = "0 4px 10px rgba(0, 123, 255, 0.3)")}
  >
    <FaHandHoldingHeart size={20} style={{ marginRight: "20px" }} />
    Alum Support
  </a>
</div>

    </section>
  );
};

export default Banner;
