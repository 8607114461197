import React from "react";
import { ProjectCard } from "../ProjectCard";
import { Row } from "react-bootstrap";
import hydro_ps1 from "../utils_competitions/Hydro_PS1.png";
import str_ps1 from "../utils_competitions/Str_PS1.png";
import geo_ps1 from "../utils_competitions/geo_ps_1.png";
import civi from "../utils_competitions/civi.png";
import aq from "../utils_competitions/Aq.png";
import infra from "../utils_competitions/infra.png";
import seismo from "../utils_competitions/seismo.png";

import crack_ps from "../utils_competitions/crack_ps.png";
import pp from "../utils_competitions/pp.png";

import "./Competitions.css";
import { getMarginLeft } from "../../utils/getMarginLeft";

function Competitions({ isMobileView, isExpanded }) {
  const handleGetCompetitions = () => {
    window.open(
      "https://unstop.com/p/sankalan-24-iit-kanpur-civil-engineering-conclave-society-of-civil-engineers-iit-kanpur-844899",
      "_blank"
    );
  };

  const competitions = [
    // {
    //   title: "Paper Presentation Competition",
    //   description:
    //     "This competition serves as a golden opportunity for aspiring researchers across the nation to spotlight their dedication and innovation ",
    //   last_date: "18 Mar 2025",
    //   imgUrl: pp,
    //   ps_link: "https://docs.google.com/document/d/1uxHC...",
    //   reg_link: "https://unstop.com/o/8dwcIKr?lb=n6aLdjXi.",
    // }, 
    {
      title: "Civi-Preneur",
      description:
        "To create a thriving ecosystem where innovation, business acumen, and engineering expertise come together to revolutionize the civil engineering landscape",
        last_date: "18 Mar 2025",
      imgUrl: civi,
      ps_link: "https://docs.google.com/document/d/1uxHC...",
      reg_link: "https://unstop.com/o/regpfaB?lb=n6aLdjXi",
    },  
    {
      title: "Aquashield: Surface Water Quality Modeling Challenge",
      description:
        "The objective of this competition is to analyze, model, and propose sustainable solutions for improving the water quality of a selected lake or river in India. ",
        last_date: "18 Mar 2025",
      imgUrl: aq,
      ps_link: "https://docs.google.com/document/d/1uxHC...",
      reg_link: "https://unstop.com/o/Z3PdjGt?lb=n6aLdjXi",
    },  
    {
      title: "Seismo",
      description:
        "Design and construct a tower using popsicle sticks that can withstand simulated seismic conditions on a shake table. ",
        last_date: "31 March 2025",
      imgUrl: seismo,
      ps_link: "https://docs.google.com/document/d/1uxHC...",
      reg_link: "https://unstop.com/competitions/seismo-sankalan25-civil-conclave-iit-kanpur-1423522",
    },  
    {
      title: "Infra-vision",
      description:
        "The goal of this competition is to analyze, propose, and present a comprehensive redevelopment strategy for NDLS that: Enhances passenger experience, Improves operational efficiency, Promotes sustainability, Ensures seamless multimodal integration ",
        last_date: "31 March 2025",
      imgUrl: infra,
      ps_link: "https://docs.google.com/document/d/1uxHC...",
      reg_link: "https://unstop.com/competitions/infravision-sankalan25-civil-conclave-iit-kanpur-1421639",
    },
    //     {
    //   title: "Spaghetti Bridge Design Challenge",
    //   description:
    //     "Show your analytical and design skills! Build a truss bridge maximizing load-bearing capacity.",
    //   last_date: "18th January",
    //   imgUrl: str_ps1,
    //   ps_link: "https://docs.google.com/document/d/11x2RR...",
    //   reg_link: "https://unstop.com/p/spaghetti-bridge...",
    // },
    // {
    //   title: "Optimising Water Resources",
    //   description:
    //     "Develop sustainable solutions for water conservation in agriculture. Innovate for a better future!",
    //   last_date: "18th January",
    //   imgUrl: hydro_ps1,
    //   ps_link: "https://docs.google.com/document/d/1uxHC...",
    //   reg_link: "https://unstop.com/p/optimising-water...",
    // },
    // {
    //   title: "Remote Satellite Design Challenge",
    //   description:
    //     "Build a remote satellite model to measure essential climate variables. Compete with the best minds!",
    //   last_date: "18th January",
    //   imgUrl: geo_ps1,
    //   ps_link: "https://docs.google.com/document/d/1jo13...",
    //   reg_link: "https://unstop.com/p/remote-satellite...",
    // },
    // {
    //   title: "Crack Detection Challenge",
    //   description:
    //     "Use deep learning to develop an image classification model for detecting cracks in structures.",
    //   last_date: "18th January",
    //   imgUrl: crack_ps,
    //   ps_link: "https://docs.google.com/document/d/1fi9aj...",
    //   reg_link: "https://unstop.com/p/crack-detection...",
    // },
  ];

  return (
    <div className="Competitions" style={{ marginLeft: getMarginLeft(isMobileView, isExpanded) }}>
      <div className="guidelines-competitions">
        <h4>Competition Guidelines:</h4>
        <ul>
          <li>Hover over challenges to view problem statements.</li>
          <li>Click 'Register' to participate via Unstop.</li>
          <li>Registration for 'Sankalan' is separate from competition registration.</li>
          <li>
            To attend Sankalan at IIT Kanpur on 5th-6th April,{" "}
            <a href="https://unstop.com/f/iITqclk" target="_blank" rel="noopener noreferrer">
              <strong>click here</strong>
            </a>
          </li>
          <li>Some competitions have multiple rounds with fee waivers for the finals.</li>
        </ul>
      </div>

      <div className="competition-cards">
        <Row>
          {competitions.map((competition, index) => (
            <ProjectCard key={index} {...competition} />
          ))}
        </Row>
      </div>

      <div className="accomodation-btn">
        <button className="get-accomodation-btn" onClick={handleGetCompetitions}>
          Get Accommodation
        </button>
      </div>
    </div>
  );
}

export default Competitions;
