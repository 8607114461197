import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Button = styled.button`
  border: 2px solid #00d4ff;  /* Solid border for clarity */
  padding: 14px 28px;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  background: #121212;  /* Solid dark background */
  color: #00d4ff;  /* Light blue text */
  transition: all 0.3s ease;
  text-transform: uppercase;

  &:hover {
    background: #00d4ff;  /* Change background to blue on hover */
    color: white;  /* Change text color to white on hover */
  }
`;

const CardButton = ({ link }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(link);
  };

  return <Button onClick={handleButtonClick}>DETAILS</Button>;
};

const GlassContainer = styled.div`
  background: #121212;
  min-height: 100vh;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  color: #ffffff;
  font-size: 36px;
  margin-bottom: 40px;
  font-family: "'Poppins', sans-serif";
  text-transform: uppercase;
  text-align: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  width: 100%;
  justify-items: center;
  align-items: center;
`;

const Card = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 25px;
  text-align: center;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-12px);
    box-shadow: 0px 6px 30px rgba(0, 212, 255, 0.6);
  }
`;

const CardTitle = styled.h3`
  color: white;
  font-size: 24px;
  margin-bottom: 15px;
`;

const CardDescription = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  margin-bottom: 20px;
`;

function FeatureCards() {
  const events = [
    { title: "PRE-EVENTS", desc: "Exciting lead-up events to the main summit.", link: "/pre-events" },
    { title: "TALKS", desc: "Insightful discussions with industry leaders.", link: "/comingsoon" },
    { title: "COMPETITIONS", desc: "Innovative challenges for students.", link: "/competitions" },
    { title: "WORKSHOPS", desc: "Hands-on learning from experts.", link: "/workshops" },
    { title: "EXHIBITIONS", desc: "Showcasing cutting-edge tech and startups.", link: "/exhibitions" },
    { title: "CULTURAL NIGHT", desc: "An evening filled with music and joy!", link: "/comingsoon" },
  ];

  return (
    <GlassContainer>
      <Title>Our Events</Title>
      <GridContainer>
        {events.map((event, index) => (
          <Card key={index}>
            <CardTitle>{event.title}</CardTitle>
            <CardDescription>{event.desc}</CardDescription>
            <CardButton link={event.link} />
          </Card>
        ))}
      </GridContainer>
    </GlassContainer>
  );
}

export default FeatureCards;
