import React from "react";
import "./Accomodation.css";
import { getMarginLeft } from "../../utils/getMarginLeft";

function Accomodation({ isMobileView, isExpanded }) {
  const handleGetAccomodation = () => {
    window.open(
      "https://unstop.com/f/iITqclk?lb=n6aLdjXi&utm_medium=Share&utm_source=WhatsApp",
      "_blank"
    );
  };
  return (
    <div
      className="accomodation"
      style={{ marginLeft: getMarginLeft(isMobileView, isExpanded) }}
    >
      <div className="guidelines">
        <h3 style={{ fontWeight: "550" }}>Accommodation Guidelines:</h3>
        <ul>
          <li>
            Accommodation facilities for Sankalan '25 at IIT Kanpur are
            available for those who purchase the 'Accommodation' ticket along
            with their 'Registration' ticket.
          </li>
          <li>The Accommodation ticket is priced at Rs. 500/-.</li>
          <li>
            The ticket includes meals for specific times:
            <ul>
              <li>Lunch on 5th April 2025</li>
              <li>Dinner on 5th April 2025</li>
              <li>Lunch on 6th April 2025</li>
            </ul>
          </li>
          <li>
            Participants will be provided with a mattress, pillow, and blanket.
            However, they are welcome to bring their own blankets if preferred.
          </li>
          <li>
            Additional instructions will be provided to participants upon
            entering the premises of IIT Kanpur.
          </li>
        </ul>
      </div>
      <div className="accomodation-btn">
        <button
          className="get-accomodation-btn"
          onClick={handleGetAccomodation}
        >
          <strong>Get Accommodation</strong>
        </button>
      </div>
    </div>
  );
}

export default Accomodation;
